import * as React from "react"
import { graphql } from "gatsby"

import { Page, NavBar, Section, ArticleSnapshot } from "src/components"

export const query = graphql`
  query AllArticles {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        timeToRead
        frontmatter {
          title
          subtitle
          slug
          date
          thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage: React.FC<{ data: GatsbyTypes.AllArticlesQuery }> = ({ data }) => {
  const articleList = data?.allMarkdownRemark?.nodes?.map((article) => {
    const { timeToRead } = article
    const { slug, title, subtitle, thumbnail, date } = article.frontmatter || {}
    const fluidThumbnail = thumbnail?.childImageSharp?.fluid
    return (
      <ArticleSnapshot
        slug={slug}
        title={title}
        subtitle={subtitle}
        fluidThumbnail={fluidThumbnail}
        date={date}
        timeToRead={timeToRead}
      />
    )
  })

  return (
    <Page>
      <NavBar isHero={true} />
      <Section>
        <p>
          My name is Sanjay and this is my blog. I love to code, cook and watch movies.
          I'm the cofounder of Rune (YC W19). Before this, I studied Social Networks at
          MIT and Physics at IIT Madras.
        </p>
      </Section>
      <Section>{articleList}</Section>
    </Page>
  )
}

export default IndexPage
